<template>
  <div>
    <div class="titleText primary--text py-4">
      <span>Forget Password</span>
    </div>
    <v-form lazy-validation ref="forgetForm">
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            autofocus
            v-model="email"
            label="Email"
            :rules="[rules.required, rules.email]"
            height="42px"
            :disabled="resendBtn || loading"
            @keyup.enter="sendedLink"
            type="email"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="text-center pb-2" style="margin-top: -18px"
          ><span :style="!resendBtn ? 'opacity:0' : ''">
            Resend in {{ timer }}s</span
          >
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
            class="primary buttonClass text-capitalize"
            height="48px"
            :disabled="resendBtn"
            @click="sendedLink"
            :loading="loading"
          >
            <span v-if="!newbtn">Send</span>
            <span v-else>Resend</span>
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-center py-3 forgetpassword orange--text">
          <span class="pointer" @click="goLogin">Sign In</span></v-col
        >
      </v-row>
    </v-form>
  </div>
</template>
<script>
import RULES from "@/common/fieldRules";
import { mapActions } from "vuex";

export default {
  name: "forget",
  data() {
    return {
      rules: RULES,
      resendBtn: false,
      loading: false,
      email: "",
      timer: 10,
      newbtn: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
    }),
    sendedLink() {
      if (this.$refs.forgetForm.validate()) {
        this.loading = true;
        const successHandler = (res) => {
          this.timer = 10;
          this.resendBtn = true;
          this.newbtn = true;
          this.setIntervalTime();
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
        };
        const failureHandler = () => {
          this.loading = false;
        };
        let formData = {};
        formData["email"] = this.email;

        return this.$request(
          this.$keys.POST,
          this.$urls.auth.passwordGenerateLink,
          {
            data: formData,
            onSuccess: successHandler,
            onFinally: failureHandler,
            isTokenRequired: false
          }
        );
      }
    },
    setIntervalTime() {
      setInterval(() => {
        if (this.timer > 0) {
          this.timer = this.timer - 1;
          if (this.timer === 0) {
            this.resendBtn = false;
          }
        }
      }, 1100);
    },
    goLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style scoped>
.buttonClass {
  width: 165px;
  border-radius: 5px;
}
.forgetpassword {
  font-weight: 400;
  font-size: 13px;
  line-height: 48px;
}
</style>
 


